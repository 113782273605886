import React, { ReactElement } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';
import { ComingSoon } from '../components/ComingSoon';
import { DateTime } from '../components/DateTime';
import { Hero } from '../components/Hero';
import { Icon } from '../components/Icon';
import { ImageFromUrlProps } from '../components/ImageFromUrl';
import { ImageRasterProps } from '../components/ImageRaster';
import { Main } from '../components/Main';
import { RichText, RichTextData } from '../components/RichText';
import { usePageContext } from '../context/PageContext';
import { ColorScheme } from '../theme/colors';
import { Callout } from '../components/Callout';
import { LayoutGrid, LayoutGridItem } from '../components/LayoutGrid';

export type EventPostImportedQuery = {
  contentfulPageEventPostImported: {
    contentful_id: string;
    title: string;
    description?: string;
    slug: string;
    image: {
      gatsbyImageData?: ImageRasterProps['image'];
      fixed?: { src: ImageFromUrlProps['image'] };
    };
    colorScheme: ColorScheme;
    start: string;
    venueName?: string;
    venueAddress?: string;
    ticketPrice?: string;
    content?: RichTextData;
  };
};

const EventPostImported = ({
  data,
}: PageProps<EventPostImportedQuery>): ReactElement => {
  const { locale } = usePageContext();

  const {
    title,
    image,
    colorScheme,
    start,
    venueName,
    venueAddress,
    ticketPrice,
    content,
  } = data.contentfulPageEventPostImported;

  return (
    <>
      {locale === 'fi' ? null : (
        <aside>
          <LayoutGrid>
            <LayoutGridItem variant={'heroNarrow'}>
              <Callout>
                Events from before August 2022 are only available in Finnish.
                Sorry for the inconvenience!
              </Callout>
            </LayoutGridItem>
          </LayoutGrid>
        </aside>
      )}
      <Main
        lang={locale === 'fi' ? undefined : 'fi'}
        variant={'twoColumn'}
        pageTitle={title}
        hero={
          <Hero
            heroImage={image}
            heroColorScheme={colorScheme}
            variant={'narrow'}
          />
        }
        rightColumn={
          <Box
            backgroundColor={`${colorScheme}.500`}
            color={'text.onColor'}
            padding={'1.5rem'}
            borderRadius={'m'}
            sx={{
              '& p:first-of-type': { marginTop: 0 },
              '& p:last-of-type': { marginBottom: 0 },
            }}
          >
            <Text marginBottom={'0.5rem'}>
              <DateTime start={start} condensed />
            </Text>
            {venueName || venueAddress ? (
              <Text fontSize={'xs'} fontWeight={700} marginBottom={'0.5rem'}>
                <HStack align={'flex-start'} as={'span'}>
                  <Icon.MapMarker fontSize={'1.5em'} />
                  <span>
                    {venueName}
                    {venueName && venueAddress ? ', ' : ''}
                    {venueAddress}
                  </span>
                </HStack>
              </Text>
            ) : null}
            {ticketPrice ? (
              <Text fontSize={'xs'} fontWeight={700} marginBottom={'0.5rem'}>
                <HStack align={'flex-start'} as={'span'}>
                  <Icon.Ticket fontSize={'1.5em'} />
                  <span>{ticketPrice}</span>
                </HStack>
              </Text>
            ) : null}
          </Box>
        }
      >
        {!content ? <ComingSoon /> : <RichText data={content} />}
      </Main>
    </>
  );
};

export default EventPostImported;

export const query = graphql`
  query ($id: String!) {
    contentfulPageEventPostImported(id: { eq: $id }) {
      ... on ContentfulPageEventPostImported {
        ...EventPostImported
      }
    }
  }
`;
